import React, { useState, useEffect } from 'react';
import { Box, Button, InputLabel, MenuItem, TextField } from '@mui/material';
import certificates from 'modules/certificates';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ResultData from 'app/mmEsoft/module/ResultData';
import ViewCertificate from './ViewCertificate';
import userCertificates from 'modules/userCertificates';

function Certificates({ candidate, getCandidateByBatchId, batchId }) {
  const [certificatesData, setCertificatesData] = useState([]);
  const [data, setData] = useState({
    certificateId: candidate.certificate_id,
    certificateRemarks: candidate.certificate_remarks,
    serialNumber: '',
    issueDate: '',
  });
  // console.log(data.serialNumber)
  // console.log(data.issueDate)
  // console.log({
  //   certificateID: data.certificateId,
  //   certificateRemarks: data.certificateRemarks,
  // });
  // console.log({ candidate });
  const [viewCertificate, setViewCertificate] = useState(false);
  const Swal = useSwalWrapper();
  // console.log(viewCertificate);
  useEffect(() => {
    allCertificates();
    fetchSavedCertificateData();
  }, []);

  const allCertificates = () => {
    certificates.getAllCertificates((response) => {
      if (response.status === 'success') {
        setCertificatesData(response.data);
      } else {
        setCertificatesData([]);
      }
    });
  };

  // TODO: Create UI for displaying all certificates. Currently only 1 certificate is shown on the Results Page for each User.s
  const fetchSavedCertificateData = () => {
    userCertificates.getUserCertificatesById(candidate.user_id, (response) => {
      if (response.status === 'success') {
        const certificate = response.data[0];
        if (certificate) {
          setData({
            certificateId: certificate.certificateId,
            certificateRemarks: '',
            serialNumber: certificate.serialNumber,
            issueDate: certificate.issueDate,
          });
        }
      }
    });
  };

  const handleIssueCertificate = () => {
    const newData = {
      ...data,
      certified: true,
    };

    ResultData.issueCertificate(
      candidate.user_id,
      // There should be candidateId or userId .. their is some isssue
      newData,
      (response) => {
        if (response.status === 'success') {
          Swal.fire({
            icon: 'success',
            title: 'Data Updated Successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error Occurred While Updating Data!',
          });
        }
      }
    );
  };

  const handleEditCertificate = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSerialNumber = (certificateName) => {
    setData((prevData) => ({
      ...prevData,
      serialNumber: `${certificateName}-${candidate.id}-${candidate.user_id}`,
    }));
  };

  const handleViewCertificate = () => {
    setViewCertificate(true);
  };

  return (
    <Box>
      <TextField
        fullWidth
        label="Certificate Templates"
        variant="outlined"
        select
        style={{ marginBottom: '2%' }}
        value={data.certificateId}
        name="certificateId"
        onChange={handleEditCertificate}
      >
        {certificatesData.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            onClick={() => handleSerialNumber(option.certificateName)}
          >
            {option.certificateName}
          </MenuItem>
        ))}
      </TextField>

      <InputLabel>Serial Number</InputLabel>
      <TextField
        fullWidth
        readOnly
        margin="dense"
        type="text"
        variant="outlined"
        value={data.serialNumber}
      />

      <InputLabel>Issue Date</InputLabel>
      <TextField
        fullWidth
        margin="dense"
        type="date"
        variant="outlined"
        name="issueDate"
        value={data.issueDate}
        onChange={handleEditCertificate}
      />

      <InputLabel>Remarks</InputLabel>
      <TextField
        fullWidth
        multiline
        rows={4}
        margin="dense"
        type="text"
        variant="outlined"
        name="certificateRemarks"
        value={data.certificateRemarks}
        onChange={handleEditCertificate}
      />

      <Box sx={{ marginTop: '2%', display: 'flex', gap: '1rem' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewCertificate}
        >
          View Certificate
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleIssueCertificate}
        >
          Issue Certificate
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleIssueCertificate}
        >
          Save
        </Button>
      </Box>
      {viewCertificate && (
        <ViewCertificate
          setOpen={setViewCertificate}
          open={viewCertificate}
          certificateData={candidate}
          data={data}
        />
      )}
    </Box>
  );
}

export default Certificates;
