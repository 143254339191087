import axios from "axios";
import Auth from "../Auth";
import Cookies from "js-cookie";

export const appServerURLJava = "https://dev-api.marvelminds.in/marvelminds";

const MarvelMindsApi = (config) => {
  const token = Auth?.getToken()?.token;

  if (token != null) {
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      //   Authorization: 'Bearer ' + token,
      Authorization: `Bearer ${token}`,
    };
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: "INETRNAL SERVER ERROR",
          status: 500,
        };
      }
      if (error.response.status === 401) {
        Auth?.getToken()
          ? Cookies.remove("authData")
          : Auth.removeLoginDetails();
        throw error;
      }
      return Promise.reject(error);
    }
  );
  config.baseURL = appServerURLJava;
  return axios(config);
};

export default MarvelMindsApi;
