import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Typography,
  Box,
} from '@mui/material';

import CopyToken from './CopyToken';
import useValidateSessions from '../hooks/useValidateSessions';
import CreateDailogSession from './CreateDailogSession';
import SessionModule from 'app/mmEsoft/module/SessionModule';
import { useState } from 'react';

const GenerateToken = ({
  open,
  setOpen,
  Swal,
  batchId,
  setSessionLoader,
  getMeetingsByBatchId,
}) => {
  const [genrateToken, setGenrateToken] = useState('');
  const [loading, setLoading] = useState(false);
  const { isSessionValid, validLoader } = useValidateSessions();

  const approveSession = () => {
    SessionModule.approveSession((response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Session is approved',
        });
        window.location.reload();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'session is not approved',
        });
      }
    });
    setTimeout(() => {
      setLoading(true);
      SessionModule.getCode((response) => {
        if (response.status == 'success') {
          Swal.fire({
            icon: 'success',
            title: 'code is genrated',
          });
          setLoading(false);
          setGenrateToken(response.data);
        } else {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'code is not generated',
          });
        }
      });
    }, 2000);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      {!isSessionValid ? (
        <>
          <DialogTitle>Generate Token</DialogTitle>
          <DialogContent>
            <Typography
              marginY="10px"
              variant="h1"
              color="red"
              textAlign="center"
            >
              Opps! Your Token is Expired
            </Typography>
            <Typography
              marginY="10px"
              variant="h3"
              color="#212121"
              textAlign="center"
            >
              Generate the code and paste it in the link below
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <CopyToken loading={loading} genrateToken={genrateToken} />
              <Button
                onClick={approveSession}
                variant="contained"
                sx={{ marginX: '10px' }}
              >
                Generate Token
              </Button>
            </Box>
            <Button
              component="a"
              href="https://login.microsoftonline.com/common/oauth2/deviceauth"
              target="_blank"
              fullWidth
              variant="contained"
            >
              Create Session
            </Button>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <CreateDailogSession
            open={open}
            setOpen={setOpen}
            Swal={Swal}
            batchId={batchId}
            setSessionLoader={setSessionLoader}
            getMeetingsByBatchId={getMeetingsByBatchId}
          />
        </>
      )}
    </Dialog>
  );
};

export default GenerateToken;
