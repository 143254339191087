import './Result.css';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminBatchesDropDown from 'app/layouts/shared/headers/Header/adminBatchesDropDown';
import Result_Tabs from './Result_Tabs';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import Assignment from './Assignment';
import Courses from './Courses';
import Attendance from './Attendence';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ResultData from 'app/mmEsoft/module/ResultData';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';

function Result() {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('1');
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState();
  const [candidateList, setCandidateList] = useState([]);
  const [candidateId, setCandidateId] = useState([]);
  const [userName, setUserName] = useState([]);
  const [totalAttendence, setTotalAttendence] = useState([]);
  const [batchId, setBatchId] = useState();
  const [assignmentData, setAssignmentData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [attendenceData, setAttendanceData] = useState([]);
  const [currentCandidatesData, setCurrentCandidatesData] = useState([]);
  const [currentCandidate, setCurrentCandidate] = useState([]);
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  // Define items per page
  const itemsPerPage = 6;

  // useEffect(() => {
  //   getCandidateByBatchId();
  // }, []);

  useEffect(() => {
    setCurrentPage(1); // Reset page when data changes
  }, [candidateList]);

  const handleClickOpen = (event, user_id, id, name, totalAttendence) => {
    event.stopPropagation();
    setOpen(true);
    setUserId(user_id);
    setUserName(name);
    setTotalAttendence(totalAttendence);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  // selected batch
  const handleBatchSelect = (batch) => {
    setBatchId(batch);
    getCandidateByBatchId(batch);
    getAssignmentByBatchId(batch);
  };

  // Api call start

  const getCandidateByBatchId = (batchId) => {
    setCurrentCandidatesData([]);
    ResultData.getCandidateByBatchId(batchId, (response) => {
      if (response.status === 'success') {
        setCandidateList(response?.data);
      } else {
        console.log('Error');
      }
    });
  };

  const getAssignmentByBatchId = (batch) => {
    ResultData.getAssignmentByBatchId(batch, (response) => {
      if (response.status === 'success') {
        setAssignmentData(response?.data);
      } else {
        console.log('Error');
      }
    });
  };

  // Pagination
  const indexOfLastCandidate = currentPage * itemsPerPage;
  const indexOfFirstCandidate = indexOfLastCandidate - itemsPerPage;
  const currentData = candidateList.length > 0 ? candidateList : [];
  const currentCandidates = currentData.slice(
    indexOfFirstCandidate,
    indexOfLastCandidate
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Result</h1>
        <div>
          <AdminBatchesDropDown onBatchSelect={handleBatchSelect} />
        </div>
      </Box>

      {candidateList.length > 0 ? (
        <Box>
          <Box>
            <Box id="header">
              <Typography>Name</Typography>
              <Typography>Quiz Percentage</Typography>
              <Typography>Attendance</Typography>
              <Typography>Certified</Typography>
            </Box>
            <div>
              {candidateList.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    sx={{ marginTop: '15px' }}
                    onClick={() => {
                      setUserId(item.user_id);
                    }}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ width: '100%' }}
                    >
                      <Typography
                        id="userName"
                        onClick={(event) =>
                          handleClickOpen(
                            event,
                            item.user_id,
                            item.id,
                            item.name,
                            item.total_attendence
                          )
                        }
                        sx={{ width: '25%', flexShrink: 0, paddingLeft: '5px' }}
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        sx={{ width: '25%', color: 'text.secondary' }}
                      >
                        {item?.quiz_percentage
                          ? item?.quiz_percentage?.toFixed(2)
                          : '---'}
                        %
                      </Typography>
                      <Typography
                        sx={{ width: '25%', color: 'text.secondary' }}
                      >
                        {item?.total_attendence
                          ? Number(item?.total_attendence).toFixed(2)
                          : 0}
                        %
                      </Typography>
                      <Typography
                        sx={{ width: '25%', color: 'text.secondary' }}
                      >
                        {!item?.certified ? (
                          <span style={{ color: 'gray' }}>
                            {' '}
                            <CheckIcon />{' '}
                          </span>
                        ) : (
                          <span style={{ color: 'green' }}>
                            {' '}
                            <CheckIcon />{' '}
                          </span>
                        )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Result_Tabs
                        key={item.user_id}
                        userId={item.user_id}
                        candidate={item}
                        batchId={batchId}
                      />
                    </AccordionDetails>{' '}
                  </Accordion>
                );
              })}
            </div>
          </Box>

          <div>
            <Button
              disabled={currentPage === 1}
              onClick={() => paginate(currentPage - 1)}
            >
              <ChevronLeftIcon />
            </Button>
            <Button
              disabled={currentCandidates.length < itemsPerPage}
              onClick={() => paginate(currentPage + 1)}
            >
              <ChevronRightIcon />
            </Button>
          </div>
        </Box>
      ) : (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '900px',
            },
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Assignment" value="1" />
                  <Tab label="Courses" value="2" />
                  <Tab label="Attendance" value="3" />{' '}
                </TabList>
              </Box>
              <TabPanel value="1" maxWidth={100}>
                <Assignment userId={userId} batchId={batchId} />
              </TabPanel>
              <TabPanel value="2">
                <Courses batchId={batchId} userId={userId} />
              </TabPanel>
              <TabPanel value="3">
                <Attendance
                  userId={userId}
                  userName={userName}
                  totalAttendence={totalAttendence}
                />
              </TabPanel>{' '}
            </TabContext>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Result;
