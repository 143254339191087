import React, { useState } from 'react';
import RecentActivities1 from '../../shared/widgets/RecentActivities1';
import campaigns from 'modules/campaigns';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCampaigns } from 'app/redux/actions/userCampaigns';
import VisitCount from 'app/shared/metrics/VisitsStatistics/VisitCount';
import lessons from 'modules/lessons';
import { Grid } from '@mui/material';
import PropertiesList1 from 'app/shared/widgets/PropertiesList1/PropertiesList1';
import { useTranslation } from 'react-i18next';
import PopularAgents from 'app/shared/widgets/PopularAgents';
import SessionList from 'app/mmEsoft/components/SessionList';
import Batches from 'app/mmEsoft/module/Batches';
import SessionListAdmin from 'app/mmEsoft/components/Admin/SessionListAdmin';

const ClientDashBoard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [lessonData, setLessonData] = React.useState([]);
  const [resentVistors, setResentVisitors] = React.useState([]);
  const [userBatches, setUserBatches] = useState();
  const [count, setCount] = React.useState([]);
  const { user } = useSelector(({ login }) => login);
  const { campaignList } = useSelector(
    ({ campaignsReducer }) => campaignsReducer
  );

  React.useEffect(async () => {
    dispatch(getUserCampaigns(user?.id));

    if (user?.roleId == 2 || user?.roleId == 5) getUserCampaignCount(user?.id);
    else getCampaignCount();
    getAllLessons();
    // getBatchByUserId();
  }, []);

  const getAllLessons = () => {
    lessons.getAllLessons((response) => {
      if (response.status === 'success') {
        setLessonData(response.data);
      } else {
        setLessonData([]);
      }
    });
  };
  const getCampaignCount = () => {
    campaigns.getCountOfCampaigns((response) => {
      if (response.status === 'success') {
        setCount(response.data);
      } else {
        setCount([]);
      }
    });
  };

  const getUserCampaignCount = (id) => {
    campaigns.getCountOfUserCampaigns(id, (response) => {
      if (response.status === 'success') {
        setCount(response.data);
      } else {
        setCount([]);
      }
    });
  };
  return (
    <>
      <Grid container spacing={3.75}>
        {/* <Grid item xs={12} sm={6} lg={3}>
          <VisitCount
            count={count}
            bgColor='#42A5F5'
            content='All the Campaigns present in this Training and Awareness Program'
            cardHeaderTitle={t('pages.title.allCampaigns')}
            usedInClient={true}
            type={'all'}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} lg={3}>
          <VisitCount
            count={count}
            bgColor='#42A5F5'
            content='Active Campaigns that are already running'
            cardHeaderTitle={t('pages.title.activeCampaigns')}
            usedInClient={true}
            type={'active'}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} lg={3}>
          <VisitCount
            count={count}
            bgColor='#42A5F5'
            content='Campaigns that have been completed so far'
            cardHeaderTitle={t('pages.title.campaignsCompleted')}
            usedInClient={true}
            user={user}
            type={'completed'}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} lg={3}>
          <VisitCount
            count={count}
            bgColor='#42A5F5'
            content='Upcoming Campaigns which will be starting '
            cardHeaderTitle={t('pages.title.upcomingCampaigns')}
            usedInClient={true}
            type={'upcoming'}
          />
        </Grid> */}
      </Grid>

      {user?.roleId == 3 && (
        <Grid item xs={12} lg={4} marginTop={6}>
          {/* <PopularAgents /> */}
          <SessionList campaignList={campaignList} />
        </Grid>
      )}

      {user?.roleId == 1 && (
        <Grid item xs={12} lg={4} marginTop={6}>
          {/* <PopularAgents /> */}
          <SessionListAdmin />
        </Grid>
      )}

      {/* 
      <Grid container spacing={3.75} sx={{ mt: '2px' }}>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          {lessonData?.length > 0 ? (
            <PropertiesList1
              id="properties-list"
              style={{
                width: '100%',
              }}
              scrollHeight={390}
              lessonData={lessonData}
              user={user}
            />
          ) : null} 
        </Grid>
           <Grid item xs={12} lg={4}>
          <RecentActivities1 scrollHeight={590} />
        </Grid> 
      </Grid> */}
    </>
  );
};

export default ClientDashBoard;
// <Grid mt={1}>
//    <DealsAnalytics />

// </Grid>
